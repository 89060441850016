import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 알뜰폰 유심요금제 가입자, 6월 한달 간 카카오쇼핑 포인트 1만 원 지급 이벤트",
  "| 인기 많은 요금제 한해, 24개월 150GB 추가 제공도",
  "| “알뜰폰에는 제휴 혜택 적다? 옛말!” 알뜰폰 인식개선 및 다양한 제휴로 차별화",
]

const content = () => (
  <div>
    <style>
      {
        "\
          .pressDetailPage .pressDetailBody .contentArea .underline {\
            text-decoration: underline;\
          }\
          .pressDetailPage .pressDetailBody .contentArea .word-break-all {\
            word-break: break-all;\
          }\
        "
      }
    </style>
    <p className="word-break-all">
      <br />
      스테이지파이브가 ‘핀다이렉트샵’ 알뜰폰 유심 요금제 4종을 출시한 지 100일이 되었다.
      <br />
      <br />
      출시 이래 뜨거운 고객 반응에 힘입어 감사의 마음을 전하고자 6월 가입자에 혜택을 전하는 풍성한 이벤트를 진행한다.
      <br />
      <br />
      먼저, 6월 한 달간 스테이지파이브 ‘핀다이렉트샵’ 및 핀플레이 홈페이지(
      <a
        href="https://www.pinplay.co.kr/mvno/usim-fare"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "underline" }}
      >
        https://www.pinplay.co.kr/mvno/usim-fare
      </a>
      )를 통해 알뜰폰 요금제에 가입하는 모든 고객에게 카카오쇼핑 1만 포인트 혜택을 제공한다.
      <br />
      <br />
      이어 지난 3월 출시한 요금제 4종 중 ‘완전자유 11GB+’ 요금제에 한해서 가입 고객에 데이터 150GB를 24개월간 추가로 제공한다.
      해당 요금제는 출시 이래 가장 많은 고객이 선호하는 것으로 집계됐다.
      <br />
      <br />
      ‘데이터 150GB 추가제공 이벤트’는 기존 런칭 프로모션에 데이터 제공량을 추가하는 것으로, ‘핀다이렉트샵’ 알뜰폰 유심 요금제
      4종을 출시 당시부터 모든 요금제에 4~100GB까지 데이터를 추가로 제공해 왔다.
      <br />
      <br />
      이번 프로모션에 따라 6월 한 달간 ‘완전자유 11GB+’요금제에 가입하는 고객은 기존 제공량 보다 50GB가 많은 150GB를 24개월간
      추가로 지원받게 된다. 해당 요금제를 2년 간 이용한다고 가정하면, 요금 프로모션까지 합쳐 월 23,330원에 161GB를 이용하는
      셈이다. 롯데카드 ‘Mobile x LOCA’ 가입 고객이면, 15,000~20,000원 청구 할인까지 받을 수 있다.
      <br />
      <br />
      스테이지파이브 이제욱ㆍ서상원 공동대표는 “내부 집계 결과, 알뜰폰 유심 요금제 신규 가입자의 80%가 MZ세대에 해당하는 2~30대인
      것으로 나타나 MZ세대가 반길 만한 이벤트를 진행했다”고 전하며, “MVNO 사업자도 이제는 단순히 저렴한 가격에만 머무는 시대가
      아닌 만큼, 알뜰폰은 혜택이 적다는 편견을 정면으로 돌파하는 한편, 이용자의 라이프 스타일에 맞춰 보다 다양한 통신 서비스를
      제공하겠다”고 전했다.
      <br />
      <br />
    </p>
    <div className="descImage">
      <div className="img">
        <img src="/images/press/13/yLNjuzUi.jpg" alt="Figure1" />
      </div>
      <div className="desc">
        △스테이지파이브(공동대표 이제욱ㆍ서상원)가 운영하는 ‘핀다이렉트샵’이 <br />
        고객 혜택 강화 차원에서 6월 한달 간 다양한 혜택을 제공한다.
      </div>
    </div>
    <p>
      <br />
      <br />
      {`스테이지파이브는 지난 2일 초저가로 MVNO(알뜰폰) 5G 및 LTE 유심 요금제 4종을 출시한 바 있다. 출시기념 프로모션 할인가로 ▲<5G 슬림 10GB+> 월 27,000원(데이터 제공량 10GB+1Mbps, 전화통화 및 문자 무제한)▲LTE <완전자유 11GB+> 월 23,330원 (데이터 제공량 11GB+일2GB+3Mbps) ▲LTE <데이터안심 15GB+> 월 28,000원(데이터 제공량 15GB+3Mbps, 음성100분, 문자100건) ▲LTE <완전자유 1.4GB+> 16,000원(데이터 무제한 1.4GB+1Mbps, 음성 및 문자 무제한)을 제공하고 있다.`}
      <br />
      <br />
    </p>
  </div>
)

const press13 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 5월 31일 즉시 배포 가능 (6월 1일 조간 요청)"
      pageInfo="*총 2매/ 첨부: 사진 2매"
      title="스테이지파이브 ‘핀다이렉트샵’ 알뜰폰 유심 요금제 출시 100일!"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press13
